import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
    AppBar,
    Box,
    Toolbar,
    makeStyles,
    IconButton,
    Hidden,
    Dialog,
    withStyles,
    ListItemText,
    Typography,
    MenuItem,
    Menu,
    Grid,
    TextField,
    InputAdornment,
    Avatar,
    Button,
    FormControl,
} from "@material-ui/core";
import { BsFillChatDotsFill } from "react-icons/bs";
import { NavLink } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";
import { Link, useHistory } from "react-router-dom";
import { IoMdAddCircle } from "react-icons/io";
import Logo from "src/component/Logo";
import { MdNotifications } from "react-icons/md";
import GlobalSearchField from "src/component/GlobalSearchField";



const StyledMenuItem = withStyles((theme) => ({
    root: {
        "&:focus": {
            // backgroundColor: theme.palette.primary.main,
            "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);
const StyledMenu = withStyles({
    paper: {
        backgroundColor: "#FFFFFF !important",
        minWidth: "120px !important",
        boxShadow: "0px 0px 11px rgba(0, 0, 0, 0.16)",
        borderRadius: "5px"
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "center",
        }}
        {...props}
    />
));



const useStyles = makeStyles((theme) => ({
    menuButton: {
        fontSize: "12px",
        fontWeight: "500",
        borderRadius: 0,
        minWidth: "auto",
        color: "#404040",
        padding: "0px 14px",
        letterSpacing: "1px",
        marginLeft: "15px",
        textDecoration: "none",
        lineHeight: "54px",
        display: "flex",
        "& span": {
            display: "flex",
            alignItems: "center",
        },
        "@media (max-width: 1280px)": {
            fontStyle: "normal",
            letterSpacing: "1px",
            lineHeight: "7px",
            color: theme.palette.primary.main,
            padding: "12px 0px !important",
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            height: "auto",
        },
        "&.activeMenu": {
            color: "#6fcfb9",
            position: "relative",
            "&:before": {
                content: "' '",
                position: "absolute",
                top: "54px",
                left: " 0px",
                width: "100%",
                height: "5px",
                background: "#6fcfb9",
                [theme.breakpoints.down("md")]: {
                    display: "none",
                },
            },
        },
        "&:hover": {
            color: "#6fcfb9",
        },
    },
    menuul: {
        display: "flex",
        listStyle: "none",
        padding: "0",
        alignItems: "center",
        height: "30px",
        justifyContent: "space-between",
        cursor: "pointer",
        [theme.breakpoints.down("md")]: {
            display: "block",
            "& .logoDesk": {
                display: "none",
            },
        },
    },
    inerrMenu: {
        display: "flex",
        listStyle: "none",
        padding: "0",
        whiteSpace: "nowrap",
        paddingLeft: "25px",
        // alignItems: "center",
        justifyContent: "flex-start",
        cursor: "pointer",
        [theme.breakpoints.down("sm")]: {
            display: "block",
            paddingLeft: "5px",
        },
        "& li": {
            "&.active": {
                background: "red",
            },
        },
    },
    logo: {
        marginRight: theme.spacing(2),
    },
    link: {
        fontWeight: theme.typography.fontWeightMedium,
        "& + &": {
            marginLeft: theme.spacing(2),
        },
    },
    divider: {
        width: 1,
        height: 32,
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    desktopDrawer: {
        position: "absolute",
        right: 80,
        top: 30,
        width: 450,
        // background: theme.palette.primary.main,
        height: 450,
        [theme.breakpoints.down("sm")]: {
            width: 300,
            right: 0,
        },
    },
    iconbutton: {
        position: "relative",
        [theme.breakpoints.down("xs")]: {
            marginRight: "0px",
        },
        "& div": {
            height: "5px",
            width: "5px",
            borderRadius: "50%",
            backgroundColor: "#6FCFB9",
            position: "absolute",
            top: "7px",
            right: "8px",
        },
    },
    mainheader: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        "& .leftBox": {
            width: "306px",
            [theme.breakpoints.down("md")]: {
                width: "200px",
            },
            [theme.breakpoints.down("xs")]: {
                width: "150px",
            },
            "& img": {
                [theme.breakpoints.down("xs")]: {
                    paddingLeft: "0 !important",
                },
            },
        },
    },
    searchBoxClass: {
        [theme.breakpoints.down("xs")]: {
            marginTop: "10px",
        },
    },
    notification: {
        margin: "0px 10px",
        "& button": {
            backgroundColor: "#F5F5F5",
            "& svg": {
                color: "#404040"
            }
        }
    },
    addStoryMenu: {
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        padding: "5px 0px",
        "& figure": {
            margin: "0px",
            width: "15px",
            height: "15px",
            "& img": {
                width: "100%"
            }
        },
        "& p": {
            marginLeft: "10px"
        }
    },
    ProfileNotification: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
    }
}));

const VendorTopBar = ({ className, onMobileNavOpen, ...rest }) => {
    const classes = useStyles();

    return (
        <AppBar className={clsx(classes.root, className)} color="default" {...rest}>
            <Toolbar className={classes.toolbar}>
                <TopBarData />
            </Toolbar>
        </AppBar>
    );
};

VendorTopBar.propTypes = {
    className: PropTypes.string,
};
VendorTopBar.defaultProps = {
    onMobileNavOpen: () => { },
};

export default VendorTopBar;

export function TopBarData() {
    const {
        menuul,
        searchBoxClass,
        searchText,
        ProfileNotification,
        mainheader,
        addStoryMenu,
        iconbutton,
        notification,
        inerrMenu,
        menuButton,
    } = useStyles();
    const history = useHistory();
    const [state, setState] = useState({
        mobileView: false,
        drawerOpen: false,
    });
    const { mobileView, drawerOpen } = state;
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDrawerOpen = () =>
        setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
        setState((prevState) => ({ ...prevState, drawerOpen: false }));
    useEffect(() => {
        const setResponsiveness = () => {
            return window.innerWidth < 960
                ? setState((prevState) => ({ ...prevState, mobileView: true }))
                : setState((prevState) => ({ ...prevState, mobileView: false }));
        };
        setResponsiveness();
        window.addEventListener("resize", () => setResponsiveness());
    }, []);
    const ProfileAndNotification = (
        <Box className={ProfileNotification}>
            <Hidden xsDown>
                <IconButton
                    className={iconbutton}
                    onClick={handleClick}
                >
                    <IoMdAddCircle style={{ color: "#6FCFB9", fontSize: "30px" }} />
                </IconButton>
                <StyledMenu
                    id="customized-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <StyledMenuItem onClick={() => history.push("/vendor-add-edit-product")}>
                        <Box className={addStoryMenu}>
                            <figure>
                                <img src="images/story/AddPostIcon.png" />
                            </figure>
                            <Typography variant="body2">Add Products</Typography>
                        </Box>
                    </StyledMenuItem>
                    <StyledMenuItem onClick={() => history.push("/edit-services")}>
                        <Box className={addStoryMenu} >
                            <figure>
                                <img src="images/story/AddStoryIcon.png" />
                            </figure>
                            <Typography variant="body2">Add Services</Typography>
                        </Box>
                    </StyledMenuItem>
                </StyledMenu>
            </Hidden>
            <IconButton onClick={() => history.push("/vendor-chat")} >
                <BsFillChatDotsFill style={{ color: "#6FCFB9", fontSize: "30px" }} />
            </IconButton>
            <Box className={notification} >
                <IconButton
                    onClick={() => history.push("/vendor-notification")}
                >
                    <MdNotifications />
                </IconButton>
            </Box>
            <Box mx={1}>
                <Avatar src="images/Profile/profileimg.png" onClick={() => history.push("/vendor-profile")} style={{ cursor: "pointer" }} />
            </Box>
        </Box>
    )
    return (
        <>
            <Box className={mainheader}>
                <Grid container alignItems="center">
                    <Grid item xs={6} >
                        <Box className="leftBox">
                            <Link to="/vendor-dashboard">
                                <Logo width="125" />
                            </Link>
                        </Box>
                    </Grid>
                    <Grid item xs={6} align="right">
                        {ProfileAndNotification}
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}