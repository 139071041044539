import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import VendorLayout from "src/layouts/VendorLayout";
import HomeLayout from "src/layouts/HomeLayout";
import LoginLayout from "src/layouts/LoginLayout";
export const routes = [
  {
    exact: true,
    path: "/",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/LoginPage")),
  },
  {
    exact: true,
    path: "/login",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/Login")),
  },
  {
    exact: true,
    path: "/reset-otp",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/ResetOtp")),
  },
  {
    exact: true,
    path: "/signup",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/signup/signup")),
  },
  {
    exact: true,
    path: "/forget-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/forget-password/index")),
  },
  {
    exact: true,
    path: "/check-email",
    // guard:true,
    component: lazy(() => import("src/views/auth/signup/CheckEmail")),
  },

  {
    exact: true,
    path: "/reset-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/reset-password/index")),
  },
  {
    exact: true,
    path: "/vendor-dashboard",
    layout: VendorLayout,
    component: lazy(() => import("src/views/pages/VendorSection/Dashboard")),
  },
  {
    exact: true,
    path: "/vendor-chat",
    layout: VendorLayout,
    component: lazy(() => import("src/views/pages/VendorSection/Chat")),
  },
  {
    exact: true,
    path: "/vendor-product",
    layout: VendorLayout,
    component: lazy(() => import("src/views/pages/VendorSection/Product")),
  },
  {
    exact: true,
    path: "/vendor-add-edit-product",
    layout: VendorLayout,
    component: lazy(() =>
      import("src/views/pages/VendorSection/Product/AddEditProduct")
    ),
  },
  {
    exact: true,
    path: "/vendor-services",
    layout: VendorLayout,
    component: lazy(() => import("src/views/pages/VendorSection/Services")),
  },
  {
    exact: true,
    path: "/add-services",
    layout: VendorLayout,
    component: lazy(() =>
      import("src/views/pages/VendorSection/Services/AddService")
    ),
  },
  {
    exact: true,
    path: "/vendor-profile",
    layout: VendorLayout,
    component: lazy(() =>
      import("src/views/pages/VendorSection/VendorProfile")
    ),
  },
  {
    exact: true,
    path: "/vendor-setting",
    layout: VendorLayout,
    component: lazy(() =>
      import("src/views/pages/VendorSection/VendorSetting")
    ),
  },
  {
    exact: true,
    path: "/vendor-reward",
    layout: VendorLayout,
    component: lazy(() => import("src/views/pages/VendorSection/VendorReward")),
  },
  {
    exact: true,
    path: "/vendor-notification",
    layout: VendorLayout,
    component: lazy(() =>
      import("src/views/pages/Notification/VendorNotification")
    ),
  },
  {
    exact: true,
    path: "/edit-services",
    layout: VendorLayout,
    component: lazy(() =>
      import("src/views/pages/VendorSection/Services/EditService")
    ),
  },
  {
    exact: true,
    path: "/view-service",
    layout: VendorLayout,
    component: lazy(() =>
      import("src/views/pages/VendorSection/Services/ServiceTab")
    ),
  },
  {
    exact: true,
    path: "/edit-product",
    layout: VendorLayout,
    component: lazy(() =>
      import("src/views/pages/VendorSection/Product/EditProduct")
    ),
  },
  {
    exact: true,
    path: "/view-product",
    layout: VendorLayout,
    component: lazy(() =>
      import("src/views/pages/VendorSection/Product/ProductTabs")
    ),
  },
  {
    exact: true,
    path: "/events",
    layout: VendorLayout,
    component: lazy(() => import("src/views/pages/Event")),
  },
  {
    exact: true,
    path: "/event-detail",
    layout: VendorLayout,
    component: lazy(() => import("src/views/pages/Event/EventDetails")),
  },
  {
    exact: true,
    path: "/event-list",
    layout: VendorLayout,
    component: lazy(() => import("src/views/pages/Event/EventList")),
  },
  {
    exact: true,
    path: "/add-event",
    layout: VendorLayout,
    component: lazy(() => import("src/views/pages/Event/AddEvent")),
  },
  {
    exact: true,
    path: "/tracking-devices",
    layout: VendorLayout,
    component: lazy(() => import("src/views/pages/TrackingDevices")),
  },
  {
    exact: true,
    path: "/terms-conditions",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/TermsAndCondition")),
  },
  {
    exact: true,
    path: "/privacy-policy",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/PrivacyPolicy")),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
