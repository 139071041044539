/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
import { useLocation, matchPath, useHistory } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import ConfirmationDialog from "src/component/ConfirmationDialog";
import { MdEmojiEvents } from "react-icons/md";

import PropTypes from "prop-types";
import {
  Box,
  Drawer,
  Hidden,
  List,
  Button,
  ListSubheader,
  makeStyles,
} from "@material-ui/core";
import { FiSettings } from "react-icons/fi";
import { RiLogoutCircleLine } from "react-icons/ri";
import { IoIosContact } from "react-icons/io";
import { AiOutlineDashboard } from "react-icons/ai";
import { BsBox } from "react-icons/bs";
import { BsHeartHalf } from "react-icons/bs";
import { CiTrophy } from "react-icons/ci";
import NavItem from "./NavItem";
const sections = [
  {
    items: [
      {
        title: "Dashboard",
        icon: AiOutlineDashboard,
        href: "/vendor-dashboard",
      },
      {
        title: "Products",
        icon: BsBox,
        href: "/vendor-product",
      },
      {
        title: "Services",
        icon: BsHeartHalf,
        href: "/vendor-services",
      },
      {
        title: "Events",
        icon: MdEmojiEvents,
        href: "/events",
      },
      {
        title: "Rewards",
        icon: CiTrophy,
        href: "/vendor-reward",
      },
      {
        title: "My Profile",
        icon: IoIosContact,
        href: "/vendor-profile",
      },
      {
        title: "Settings",
        icon: FiSettings,
        href: "/vendor-setting",
      },
    ],
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }
  return acc;
}
const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
    background: theme.palette.background.default,
  },
  desktopDrawer: {
    width: 240,
    // top: 72,
    top: 63,
    // left: "30px",
    height: "100%",
    background: "#ffff",
    borderRight: "0",
    boxShadow: "4px 3px 4px rgba(0, 0, 0, 0.1)",
    padding: "0px 20px",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  button: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    height: "45px",
    paddingLeft: "17px",
    borderRadius: "12px",
    marginTop: "-30px",
    "&:hover": {
      color: "#6FCFB9",
    },
    "& svg": {
      color: "#6FCFB9",
      fontSize: "20px",
    },
  },
  LogoutBtn: {
    color: "#FE5151",
    border: "1px solid #D1D1D1",
    borderRadius: "10px",
    fontWeight: "400",
  },
  btnBox: {
    position: "absolute",
    bottom: "17%",
    right: "38%",
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box py={4}>
          {sections.map((section, i) => (
            <List
              key={`menu${i}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>
        <Box className={classes.btnBox}>
          <Button onClick={() => setOpen(true)} className={classes.LogoutBtn}>
            <RiLogoutCircleLine />
            &nbsp;Logout
          </Button>
        </Box>
      </PerfectScrollbar>
      <ConfirmationDialog
        open={open}
        handleClose={() => setOpen(false)}
        title={"Logout"}
        desc={"Do you want to Logout"}
      />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <Box p={2}>{content}</Box>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
